/* CSS imports example */

@font-face {
  font-family: 'Graphie';
  src:
    url('../../../Assets/Fonts/Graphie-Regular.woff2') format('woff2'),
    url('../../../Assets/Fonts/Graphie-Regular.woff') format('woff'),
    url('../../../Assets/Fonts/Graphie-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphie';
  src:
    url('../../../Assets/Fonts/Graphie-Bold.woff2') format('woff2'),
    url('../../../Assets/Fonts/Graphie-Bold.woff') format('woff'),
    url('../../../Assets/Fonts/Graphie-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'Graphie';
  src:
    url('../../../Assets/Fonts/Graphie-Light.woff2') format('woff2'),
    url('../../../Assets/Fonts/Graphie-Light.woff') format('woff'),
    url('../../../Assets/Fonts/Graphie-Light.ttf') format('truetype');
  font-weight: light;
  font-style: light;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Bold'),
    local('Inter-Bold'),
    url('../../../Assets/Fonts/Inter-Bold.woff2') format('woff2'),
    url('../../../Assets/Fonts/Inter-Bold.woff') format('woff'),
    url('../../../Assets/Fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Medium'),
    local('Inter-Medium'),
    url('../../../Assets/Fonts/Inter-Medium.woff2') format('woff2'),
    url('../../../Assets/Fonts/Inter-Medium.woff') format('woff'),
    url('../../../Assets/Fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Regular'),
    local('Inter-Regular'),
    url('../../../Assets/Fonts/Inter-Regular.woff2') format('woff2'),
    url('../../../Assets/Fonts/Inter-Regular.woff') format('woff'),
    url('../../../Assets/Fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MarkPro';
  src:
    url('../../../Assets/Fonts/MarkPro-Heavy.woff2') format('woff2'),
    url('../../../Assets/Fonts/MarkPro-Heavy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Moderat';
  src:
    url('../../../Assets/Fonts/Moderat-Regular.woff2') format('woff2'),
    url('../../../Assets/Fonts/Moderat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Moderat';
  src:
    url('../../../Assets/Fonts/Moderat-Bold.woff2') format('woff2'),
    url('../../../Assets/Fonts/Moderat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'Poppins';
  src:
    url('../../../Assets/Fonts/Poppins-Black.woff2') format('woff2'),
    url('../../../Assets/Fonts/Poppins-Black.woff') format('woff'),
    url('../../../Assets/Fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
